import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useSelector, useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import { Link } from "react-router-dom"
import { connect } from "formik"
import { use } from "i18next"
import { TOGGLE_LEFTMENU } from "store/layout/actionTypes"
import apiURl from "url"
import { faL } from "@fortawesome/free-solid-svg-icons"

function formatAmountCurrency(amount, currency) {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  })
}

const Dashboard = props => {
  const role = localStorage.getItem("role")
  const user_id = localStorage.getItem("login_user_id")
  const [loading, setLoading] = useState(false)

  const [parcelCounts, setParcelCounts] = useState({
    allParcel: "0",
    newParcelCount: "0",
    inProgressParcelCount: "0",
    deliveredParcelCount: "0",
  })
  const [todayParcel, setTodayParcel] = useState(0)

  const [OrderData, setOrderData] = useState([])
  const [totalRevenue, setTotalRevenue] = useState([])
  const [todayRevenue, setTodayRevenue] = useState([])
  const [todayDate, setTodayDate] = useState([])
  //Cvi Amount
  const [totalCviAmount, SetTotalCviAmount] = useState([])
  const [gstAmount, setGstAmount] = useState([])
  //Count All Parcel
  const fetchParcelCounts = () => {
    const data = {
      Role: role,
      User_id: user_id,
    }
    const URL = `${apiURl}/countParcelsByStatus`
    axios
      .post(URL, data)
      .then(response => {
        if (response.data.status === "success") {
          const {
            total_parcel_count,
            new_parcel_count,
            in_progress_parcel_count,
            delivered_parcel_count,
          } = response.data.parcelCounts
          setParcelCounts({
            allParcel: total_parcel_count || "0",
            newParcelCount: new_parcel_count || "0",
            inProgressParcelCount: in_progress_parcel_count || "0",
            deliveredParcelCount: delivered_parcel_count || "0",
          })
        } else {
          console.error("Failed to fetch parcel counts:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching parcel counts:", error)
      })
  }
  //Count Today Parcel
  const fetchTodayParcelCounts = () => {
    const data = {
      Role: role,
      User_id: user_id,
    }
    const URL = `${apiURl}/countParcelsForToday`
    axios
      .post(URL, data)
      .then(response => {
        if (response.data.status === "success") {
          const count = response.data.parcelCountForToday
          if (count) {
            setTodayParcel(count.total_parcel_count_for_today)
          } else {
            setTodayParcel(0)
          }
        } else {
          console.error("Failed to fetch parcel counts:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching parcel counts:", error)
      })
  }

  //Fetch All Parcel Data
  const fetchParcelinfo = (Role, User_id) => {
    setLoading(true)
    const data = {
      role: Role,
      user_id: User_id,
    }
    const URL = `${apiURl}/fetchParcelDetails`
    axios
      .post(URL, data)
      .then(response => {
        if (response.data.status === "success") {
          setLoading(false)
          setOrderData(response.data.parcelDetails)
        } else {
          setLoading(false)
          console.error("Failed to fetch users:", response.data.message)
        }
      })
      .catch(error => {
        setLoading(false)
        console.error("Error fetching users:", error)
      })
  }

  //ToDay Date
  const Date = () => {
    // Fetch current date from the server
    axios
      .get(`${apiURl}/currentDate`)
      .then(response => {
        const currentDate = response.data.currentDate
        setTodayDate(currentDate)
      })
      .catch(error => {
        console.error("Error fetching current date:", error)
      })
  }

  useEffect(() => {
    fetchParcelCounts()
    fetchTodayParcelCounts()
    Date()
    fetchParcelinfo(role, user_id)
  }, [role, user_id])

  //For Total Revenue
  useEffect(() => {
    let TotalParcelAmount = 0
    let TotalDocAmount = 0
    let ParcelCviAmount = 0
    let DocCviAmount = 0

    OrderData.forEach(item => {
      const Parcelamount = parseFloat(item.total_price) || 0
      const Docamount = parseFloat(item.docs_total_price) || 0
      const Pcviamount = parseFloat(item.parcel_price) || 0
      const Dcviamount = parseFloat(item.doc_cvi_amount) || 0

      TotalParcelAmount += Parcelamount
      TotalDocAmount += Docamount
      ParcelCviAmount += Pcviamount
      DocCviAmount += Dcviamount
    })

    const TotalAmount = TotalParcelAmount + TotalDocAmount
    const TotalCviAmount = ParcelCviAmount + DocCviAmount
    const CviPercent = TotalCviAmount * 0.025
    const ForGST = TotalAmount - CviPercent
    const GSTAmount = ForGST * 0.15
    const TotalAmountWithGST = TotalAmount + GSTAmount
    setGstAmount(GSTAmount)
    setTotalRevenue(TotalAmountWithGST)
    SetTotalCviAmount(TotalCviAmount)
  }, [OrderData])

  //For Today Revenue
  useEffect(() => {
    if (todayDate) {
      const todayData = OrderData.filter(
        item => item.created_date === todayDate
      )

      let TotalParcelAmount = 0
      let TotalDocAmount = 0
      let ParcelCviAmount = 0
      let DocCviAmount = 0

      if (todayData.length > 0) {
        todayData.forEach(item => {
          const Parcelamount = parseFloat(item.total_price) || 0
          const Docamount = parseFloat(item.docs_total_price) || 0
          const Pcviamount = parseFloat(item.parcel_price) || 0
          const Dcviamount = parseFloat(item.doc_cvi_amount) || 0

          TotalParcelAmount += Parcelamount
          TotalDocAmount += Docamount
          ParcelCviAmount += Pcviamount
          DocCviAmount += Dcviamount
        })

        const TotalAmount = TotalParcelAmount + TotalDocAmount
        const TotalCviAmount = ParcelCviAmount + DocCviAmount
        const CviPercent = TotalCviAmount * 0.025
        const ForGST = TotalAmount - CviPercent
        const GSTAmount = ForGST * 0.15
        const TotalAmountWithGST = TotalAmount + GSTAmount
        setTodayRevenue(TotalAmountWithGST)
      } else {
        setTodayRevenue(0)
      }
    } else {
      setTodayRevenue(0)
    }
  }, [OrderData, todayDate])

  //meta title
  document.title = "Dashboard"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Col md="3">
                  <Link to="/parcel_detail/new">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">New Courier</p>
                            <h4 className="mb-0">
                              {parcelCounts.newParcelCount}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-task font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col md="3">
                  <Link to="/parcel_detail/in-progress">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">In Progress</p>
                            <h4 className="mb-0">
                              {parcelCounts.inProgressParcelCount}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={"bx bx-loader-circle font-size-24"}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col md="3">
                  <Link to="/parcel_detail/delivered">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Delivered</p>
                            <h4 className="mb-0">
                              {parcelCounts.deliveredParcelCount}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-send font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
          <CardTitle>Total Courier</CardTitle>
          <Row className="mb-3 mt-3">
            <Col xl="12">
              <Row>
                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Today Book Courier
                            <br />
                            {todayDate}
                          </p>
                          <h4 className="mb-0">{todayParcel}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-task font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="mini-stats-wid">
                    <Link to="/all_parcel_detail">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Total Book Courier
                            </p>
                            <h4 className="mb-0">{parcelCounts.allParcel}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-task font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Link>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {role === "admin" ? (
            <>
              <CardTitle>Revenue Report</CardTitle>
              {loading ? (
                <Spinner />
              ) : (
                <Row className="mb-3 mt-3">
                  <Col xl="12">
                    <Row>
                      <Col md="3">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Today Revenue <br />
                                  {todayDate}
                                </p>
                                <h4 className="mb-0">
                                  {formatAmountCurrency(todayRevenue, "PKR")}
                                </h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={"bx bx-archive-in font-size-24"}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="3">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Total Revenue
                                </p>
                                <h4 className="mb-0">
                                  {formatAmountCurrency(totalRevenue, "PKR")}
                                </h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={"bx bx-archive-in font-size-24"}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="3">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Total GST (15%)
                                </p>
                                <h4 className="mb-0">
                                  {formatAmountCurrency(gstAmount, "PKR")}
                                </h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={"bx bx-archive-in font-size-24"}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <>
              <CardTitle>Revenue Report</CardTitle>
              {loading ? (
                <Spinner />
              ) : (
                <Row className="mb-3 mt-3">
                  <Col xl="12">
                    <Row>
                      <Col md="3">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Today Revenue <br />
                                  {todayDate}
                                </p>
                                <h4 className="mb-0">
                                  {formatAmountCurrency(todayRevenue, "PKR")}
                                </h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={"bx bx-archive-in font-size-24"}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="3">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Total Revenue
                                </p>
                                <h4 className="mb-0">
                                  {formatAmountCurrency(totalRevenue, "PKR")}
                                </h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={"bx bx-archive-in font-size-24"}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="3">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Total GST (15%)
                                </p>
                                <h4 className="mb-0">
                                  {formatAmountCurrency(gstAmount, "PKR")}
                                </h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={"bx bx-archive-in font-size-24"}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
