import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import { connect } from "react-redux"
import { Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logo from "../../assets/images/logo.svg"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const Header = props => {
  const navigate = useNavigate()
  // Function to check and handle auto logout

  useEffect(() => {
    const loginTime = localStorage.getItem("loginTime")
    const expirationTime = localStorage.getItem("expirationTime")
    const currentTime = new Date().getTime()

    // If login time is found in localStorage
    if (loginTime && expirationTime) {
      if (currentTime > expirationTime) {
        localStorage.removeItem("loginTime")
        localStorage.removeItem("expirationTime")
        navigate("/logout")
      }
    } else {
      navigate("/logout")
    }
  }, [navigate])

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <Row>
          <div className="navbar-header">
            <Col xl={6}>
              <div className="d-flex">
                <div className="navbar-brand-box d-lg-none d-md-block">
                  <Link to="/" className="logo logo-dark">
                    <span className="logo-sm">
                      <img src={logo} alt="" width={30} />
                    </span>
                  </Link>
                  <Link to="/" className="logo logo-light">
                    <span className="logo-sm">
                      <img src={logo} alt="" width={30} />
                    </span>
                  </Link>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    tToggle()
                  }}
                  className="btn btn-sm px-3 font-size-16 header-item "
                  id="vertical-menu-btn"
                >
                  <i className="fa fa-fw fa-bars" />
                </button>

                <div className="app-search d-none d-lg-block">
                  <div
                    className="position-relative mt-2 font-size-15"
                    style={{ color: "#556EE6" }}
                  >
                    Excellence Courier Service
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={6} style={{ display: "flex", justifyContent: "end" }}>
              <div className="float-end">
                <Link to={"/courier_info"}>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary px-3 font-size-13"
                    id="vertical-menu-btn"
                  >
                    Booking {""}
                    <i className="fa fa-bookmark" />
                  </button>
                </Link>
                {/* <NotificationDropdown /> */}
                <ProfileMenu />
              </div>
            </Col>
          </div>
        </Row>
      </header>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
