import React, { useEffect, useState } from "react"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  NavItem,
  NavLink,
  Form,
  Input,
  InputGroup,
  TabContent,
  TabPane,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { add } from "lodash"
import { series } from "common/data/projects"
import { connect } from "formik"
import Select from "react-select"
import { use } from "i18next"
import { check } from "prettier"
import apiURl from "url"

const CourierInfo = props => {
  //meta title
  document.title = "Courier Info"
  const navigate = useNavigate()

  const [isChecked, setIsChecked] = useState(false)
  const [MainService, setMainService] = useState("")
  const [DocSubService, setDocSubService] = useState("")
  const [DocSSOption, setDocSSOption] = useState("")
  const [SubService, setSubService] = useState("")
  const [Sector, setSector] = useState("")
  const [MSoption, setMSoption] = useState([])
  const [Rname, setRname] = useState("")
  const [RiderOption, setRideroption] = useState([])

  const [apiLoaded, setApiLoaded] = useState(false)

  const [oto, setOto] = useState(false)
  const [otd, setOtd] = useState(false)
  const [wc, setWc] = useState(false)
  const [ctc, setCtc] = useState(false)

  const login_user_id = localStorage.getItem("login_user_id")
  const role = localStorage.getItem("role")

  const [parcelWeight, setParcelWeight] = useState({
    label: "0.5",
    value: "0.5",
  })

  const [paymentM, setpaymentm] = useState(null)
  //Parcel Info
  const [FormData, setFormData] = useState({
    s_fullName: "",
    s_cnic: "",
    s_phone: "",
    s_address: "",

    r_fullName: "",
    r_phone: "",
    r_cnic: "",
    r_address: "",

    service_name: "",
    doc_sub_service: "",
    sub_service_name: "",
    sector_: "",

    content_no: "1",
    content_weight: "0",
    contents_value: "0",
    content_info: "",

    assign_to: "",

    receive_location: "",
    send_location: "",

    employee_name: "",
    employee_id: "",
    employee_city: "",

    subtotal_: "0.00",
    original_subtotal: "0",

    //Documents Data
    office_door_option: "",
    city_options: "",
    no_of_docs: "1",
    Docs_subtotal_: "0",
    cvi_percent: false,
    document_detail: "",
    doc_cvi_amount: "0",

    //Payment Method
    payment_method: "",
  })

  useEffect(() => {
    // Update content_weight based on sub_service_name
    if (FormData.sub_service_name === "box") {
      setFormData(prevState => ({
        ...prevState,
        content_weight: "1",
      }))
    } else {
      setFormData(prevState => ({
        ...prevState,
        content_weight: "0.5",
      }))
    }
  }, [FormData.sub_service_name])

  const handleParcel = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  //Service option
  const [serviceOption, setServiceOption] = useState({
    oto_wc_3docs: "",
    oto_wc_addocs: "",
    oto_ctc_3docs: "",
    oto_ctc_adddoc: "",

    otd_wc_3docs: "",
    otd_wc_addocs: "",
    otd_ctc_3docs: "",
    otd_ctc_adddoc: "",
  })
  const fetchDocumentData = SubService => {
    const URL = `${apiURl}/fetchDocumentService?sub_service=${SubService}`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const documentData = response.data.documentData
          setServiceOption({
            oto_wc_3docs: documentData[0]?.oto_wc_3docs || "0",
            oto_wc_addocs: documentData[0]?.oto_wc_Addocs || "0",
            oto_ctc_3docs: documentData[0]?.oto_ctc_3docs || "0",
            oto_ctc_adddoc: documentData[0]?.oto_ctc_Addocs || "0",

            otd_wc_3docs: documentData[0]?.otd_wc_3docs || "0",
            otd_wc_addocs: documentData[0]?.otd_wc_Addocs || "0",
            otd_ctc_3docs: documentData[0]?.otd_ctc_3docs || "0",
            otd_ctc_adddoc: documentData[0]?.otd_ctc_Addocs || "0",
          })
        } else {
          console.error("Failed to fetch Employee:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching Employee:", error)
      })
  }

  const handleOfficDoorService = event => {
    const selectedValue = event.target.value
    setFormData(prevData => ({
      ...prevData,
      office_door_option: selectedValue,
    }))
    if (selectedValue === "oto_") {
      setOto(true)
      setOtd(false)
    } else if (selectedValue === "otd_") {
      setOtd(true)
      setOto(false)
    }
  }
  const handlecityoptions = event => {
    const selectedValue = event.target.value
    setFormData(prvdata => ({
      ...prvdata,
      city_options: selectedValue,
    }))

    if (selectedValue === "wc_") {
      setWc(true)
      setCtc(false)
    } else if (selectedValue === "ctc_") {
      setCtc(true)
      setWc(false)
    } else {
      setCtc(false)
      setWc(false)
    }
  }
  const handledocsNo = event => {
    const { name, value } = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  //Fetch Doc Sub Service Data
  //Fetch Document DAta
  const fetchServiceDocumentData = () => {
    const URL = `${apiURl}/fetchServiceDocumentData`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const DocSubSoption = response.data.DocserviceData.map(row => ({
            label: row.doc_sub_service,
            value: row.doc_sub_service,
          }))
          setDocSSOption(DocSubSoption)
        } else {
          console.error("Failed to fetch sub service :", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  useEffect(() => {
    // Convert value to a number
    const numberOfDocs = parseInt(FormData.no_of_docs)
    // Initialize subtotal as a number
    let subtotal = 0

    if (oto && wc) {
      subtotal = serviceOption.oto_wc_3docs
      if (numberOfDocs > 3) {
        subtotal =
          parseInt(subtotal) +
          (numberOfDocs - 3) * parseFloat(serviceOption.oto_wc_addocs)
      }
    } else if (oto && ctc) {
      subtotal = serviceOption.oto_ctc_3docs
      if (numberOfDocs > 3) {
        subtotal =
          parseInt(subtotal) +
          (numberOfDocs - 3) * parseFloat(serviceOption.oto_ctc_adddoc)
      }
    } else if (otd && wc) {
      subtotal = serviceOption.otd_wc_3docs
      if (numberOfDocs > 3) {
        subtotal =
          parseInt(subtotal) +
          (numberOfDocs - 3) * parseFloat(serviceOption.otd_wc_addocs)
      }
    } else if (otd && ctc) {
      subtotal = serviceOption.otd_ctc_3docs
      if (numberOfDocs > 3) {
        subtotal =
          parseInt(subtotal) +
          (numberOfDocs - 3) * parseFloat(serviceOption.otd_ctc_adddoc)
      }
    } else {
      subtotal = 0
    }

    if (isChecked) {
      const CviAmount = parseFloat(FormData.doc_cvi_amount)
      const cvipercent = (2.5 / 100) * CviAmount
      subtotal = (parseInt(subtotal) || 0) + cvipercent
      setFormData(prevData => ({
        ...prevData,
        Docs_subtotal_: subtotal,
      }))
    } else {
      setFormData(prevData => ({
        ...prevData,
        Docs_subtotal_: subtotal,
      }))
    }

    // Set the subtotal value in state
    setFormData(prevData => ({
      ...prevData,
      Docs_subtotal_: subtotal,
    }))
  }, [
    serviceOption.oto_wc_3docs,
    serviceOption.oto_ctc_3docs,
    serviceOption.otd_wc_3docs,
    serviceOption.otd_ctc_3docs,
    serviceOption.oto_wc_addocs,
    serviceOption.oto_ctc_adddoc,
    serviceOption.otd_wc_addocs,
    serviceOption.otd_ctc_adddoc,
    FormData.no_of_docs,
    FormData.office_door_option,
    FormData.city_options,
    FormData.doc_cvi_amount,
    oto,
    otd,
    wc,
    ctc,
    isChecked,
  ])

  useEffect(() => {
    fetchServiceDocumentData()
  }, [])

  // Fetch Documents Service
  const handleDocCheckbox = () => {
    if (DocumentMode) {
      if (FormData.doc_cvi_amount === "" || FormData.doc_cvi_amount === "0") {
        toastr.error("Set CVI Amount")
      } else {
        setIsChecked(prevState => !prevState)
        setFormData(prevData => ({
          ...prevData,
          cvi_percent: !isChecked,
        }))
      }
    } else {
      if (FormData.contents_value === "" || FormData.contents_value === "0") {
        toastr.error("Set Content Value")
      } else {
        setIsChecked(prevState => !prevState)
        setFormData(prevData => ({
          ...prevData,
          cvi_percent: !isChecked,
        }))
      }
    }
  }

  useEffect(() => {
    // Calculate 2.5% of contents_value
    const cviAmount = parseFloat(FormData.contents_value) * 0.025
    const subtotal = parseFloat(FormData.subtotal_)
    const original_subtotal = parseFloat(FormData.original_subtotal)
    const total = subtotal + cviAmount
    // Check if the checkbox is checked and cviAmount hasn't been added yet
    if (FormData.cvi_percent === true) {
      // Add cviAmount to subtotal_
      setFormData(prevData => ({
        ...prevData,
        subtotal_: parseFloat(total).toFixed(2),
      }))
    } else {
      // Subtract cviAmount from subtotal_ if checkbox is unchecked and cviAmount has been added
      setFormData(prevData => ({
        ...prevData,
        subtotal_: parseFloat(original_subtotal).toFixed(2),
      }))
      // Set cviAdded to false to indicate that cviAmount has been subtracted
    }
  }, [
    FormData.contents_value,
    FormData.cvi_percent,
    FormData.original_subtotal,
  ])

  //const DocumentMode
  const [DocumentMode, setDocumentMode] = useState(false)

  //set Doc Main Service
  const handleMainService = selectedOption => {
    setFormData(prevData => ({
      ...prevData,
      service_name: selectedOption.value,
    }))
    if (selectedOption.value === "Docs. Attestation") {
      setDocumentMode(true)

      setFormData(prevData => ({
        ...prevData,
        r_fullName: "ECS",
        r_address: "Office # 1-LG F&F Mall G-6 Islamabad",
      }))
    } else {
      setDocumentMode(false)
      setFormData(prevData => ({
        ...prevData,
        r_fullName: "",
        r_address: "",
        Docs_subtotal_: "0",
        office_door_option: "",
        city_options: "",
        no_of_docs: "1",
      }))
    }
    setMainService(selectedOption)
  }

  //Set Doc Sub Service
  const handleDocSubService = selectedOption => {
    setFormData(prevData => ({
      ...prevData,
      doc_sub_service: selectedOption.value,
    }))
    fetchDocumentData(selectedOption.value)
    setDocSubService(selectedOption)
  }

  //Fetch Only Service
  const fetchServiceName = () => {
    const URL = `${apiURl}/fetchServiceData`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const options = response.data.serviceData.map(row => ({
            label: row.service_name,
            value: row.service_name,
          }))
          setMSoption(options)
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  //Fetch Services Detial
  const fetchSubServiceChargesData = (MS, SS, SC, PW) => {
    const URL = `${apiURl}/fetchServiceChargesData`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const serviceChargesData = response.data.serviceChargesData
          if (serviceChargesData.length > 0) {
            // Find the selected service object for the provided weight
            let selectedServiceCharges = serviceChargesData.find(service => {
              return (
                service.sub_service === SS &&
                service.service_sector === SC &&
                service.parcel_weight === PW
              )
            })

            // If service charges for the provided weight are not found, fall back to default weight (1 kg)

            if (!selectedServiceCharges && PW !== "1") {
              selectedServiceCharges = serviceChargesData.find(service => {
                return (
                  service.sub_service === SS &&
                  service.service_sector === SC &&
                  service.parcel_weight === "1" // Fall back to default weight (1 kg)
                )
              })
            }

            // For Box
            let totalServiceCharges = 0
            if (selectedServiceCharges) {
              totalServiceCharges = parseFloat(
                selectedServiceCharges.service_charges
              )

              // Check if the weight is greater than 1 kg and calculate additional charges
              if (PW > 1) {
                const extraWeightCharges = parseFloat(
                  selectedServiceCharges.extra_weight_charges
                )
                totalServiceCharges += extraWeightCharges * (PW - 1) // Calculate additional charges for each additional kg
              }

              // Apply urgency charges if applicable
              if (MS === "Urgent") {
                totalServiceCharges += 100 // Add additional urgent charges
              }
            } else {
              console.error(
                "No service charges found for the provided weight or default weight."
              )
            }

            setFormData(prevData => ({
              ...prevData,
              subtotal_: totalServiceCharges.toFixed(2), // Round to 2 decimal places
              original_subtotal: totalServiceCharges.toFixed(2),
            }))
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  //Handel Sender Location
  const handleChange = address => {
    setFormData({ ...FormData, send_location: address })
  }
  const handleSelect = async address => {
    setFormData({ ...FormData, send_location: address })
  }

  //Handle receiver Location
  const handleChange2 = address => {
    setFormData({ ...FormData, receive_location: address })
  }
  const handleSelect2 = async address => {
    setFormData({ ...FormData, receive_location: address })
  }

  //Final Parcel Submit
  const [loading, setLoading] = useState(false)
  const handleSubmit = async () => {
    if (DocumentMode) {
      if (FormData.service_name.length === 0) {
        toastr.error("Set Service Type")
      } else if (FormData.doc_sub_service.length === 0) {
        toastr.error("Set Doc Sub Service")
      } else if (FormData.office_door_option.length === 0) {
        toastr.error("Set Delivery Options")
      } else if (FormData.city_options.length === 0) {
        toastr.error("Set Delivery Options")
      } else if (FormData.no_of_docs == "" || FormData.no_of_docs == "0") {
        toastr.error("Set No Of Docs")
      } else if (FormData.s_fullName.length === 0) {
        toastr.error("Set Sender Full Name")
      } else if (FormData.s_phone.length === 0) {
        toastr.error("Set Sender Ph.no")
      } else if (FormData.s_address.length === 0) {
        toastr.error("Set Sender Address")
      } else if (FormData.payment_method.length === 0) {
        toastr.error("Set Payment Method")
      } else if (
        FormData.doc_cvi_amount !== "" &&
        FormData.doc_cvi_amount !== "0" &&
        FormData.cvi_percent == false
      ) {
        toastr.error("Check The CVI")
      } else {
        setLoading(true)
        try {
          const response = await axios.post(`${apiURl}/addParcel`, FormData)
          if (response.data.status === "success") {
            // Handle success
            setLoading(false)
            toastr.success("Parcel added successfully")
            navigate("/all_parcel_detail")
            // console.log("Parcel added successfully")
          } else {
            // Handle failure
            setLoading(false)
            console.error("Failed to add parcel:", response.data.message)
          }
        } catch (error) {
          // Handle error
          setLoading(false)
          console.error("Error adding parcel:", error)
        }
      }
    } else {
      if (FormData.service_name.length === 0) {
        toastr.error("Set Service Type")
      } else if (FormData.sub_service_name.length === 0) {
        toastr.error("Set Sub Service")
      } else if (FormData.sector_.length === 0) {
        toastr.error("Set Sector")
      } else if (FormData.send_location.length === 0) {
        toastr.error("Set Send Location")
      } else if (FormData.receive_location.length === 0) {
        toastr.error("Set Receive Location")
      } else if (FormData.s_fullName.length === 0) {
        toastr.error("Set Sender Full Name")
      } else if (FormData.s_phone.length === 0) {
        toastr.error("Set Sender Ph.no")
      } else if (FormData.s_address.length === 0) {
        toastr.error("Set Sender Address")
      } else if (FormData.r_fullName.length === 0) {
        toastr.error("Set Receiver Full Name")
      } else if (FormData.r_address.length === 0) {
        toastr.error("Set Receiver Address")
      } else if (FormData.payment_method.length === 0) {
        toastr.error("Set Payment Method")
      } else if (
        FormData.contents_value !== "" &&
        FormData.contents_value !== "0" &&
        FormData.cvi_percent == false
      ) {
        toastr.error("Check The CVI")
      } else {
        setLoading(true)
        try {
          const response = await axios.post(`${apiURl}/addParcel`, FormData)
          if (response.data.status === "success") {
            // Handle success
            setLoading(false)
            toastr.success("Parcel added successfully")
            navigate("/all_parcel_detail")
            // console.log("Parcel added successfully")
          } else {
            // Handle failure
            setLoading(false)
            console.error("Failed to add parcel:", response.data.message)
          }
        } catch (error) {
          // Handle error
          setLoading(false)
          console.error("Error adding parcel:", error)
        }
      }
    }
  }

  //Fetch Users
  const fetchUsers = () => {
    const URL = `${apiURl}/fetchUsers`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const Data = response.data.users
          const UserData = Data.filter(item => item.user_id === login_user_id)
          if (UserData) {
            setFormData(prevData => ({
              ...prevData,
              employee_name: UserData[0].full_name,
              employee_id: UserData[0].user_id,
              employee_city: UserData[0].city_,
            }))
          } else {
            setFormData(prevData => ({
              ...prevData,
            }))
          }
        } else {
          console.error("Failed to fetch users:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching users:", error)
      })
  }
  useEffect(() => {
    fetchUsers()
  }, [])

  //setRider
  const handleRider = selectedOption => {
    setFormData(prevData => ({
      ...prevData,
      assign_to: selectedOption.value,
    }))
    setRname(selectedOption)
  }
  const fetchRiders = (role, login_user_id) => {
    const data = {
      Role: role,
      User_id: login_user_id,
    }
    const URL = `${apiURl}/fetchRiders`
    axios
      .post(URL, data)
      .then(response => {
        if (response.data.status === "success") {
          const rideroption = response.data.riders.map(row => ({
            label: row.full_name,
            value: row.user_id,
          }))
          setRideroption(rideroption)
        } else {
          console.error("Failed to fetch riders:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching riders:", error)
      })
  }

  // Payment Method
  const fethcPaymentMethod = () => {
    const URL = `${apiURl}/fetchPaymentMethod`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const PMethod = response.data.PMethod
          if (PMethod.length > 0) {
            setpaymentm(PMethod)
          }
        } else {
          console.error("Failed to fetch riders:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching riders:", error)
      })
  }

  useEffect(() => {
    fetchServiceName()
    fethcPaymentMethod()
    if (
      FormData.service_name &&
      FormData.sub_service_name &&
      FormData.sector_ &&
      FormData.content_weight
    ) {
      fetchSubServiceChargesData(
        FormData.service_name,
        FormData.sub_service_name,
        FormData.sector_,
        FormData.content_weight
      )
    } else {
      setFormData(prevData => ({
        ...prevData,
        subtotal_: "0",
      }))
    }
  }, [
    FormData.service_name,
    FormData.sub_service_name,
    FormData.sector_,
    FormData.content_weight,
  ])

  useEffect(() => {
    fetchRiders(role, login_user_id)
  }, [role, login_user_id])

  //Google Apis
  useEffect(() => {
    // Check if the google object exists in the global scope
    if (window.google && window.google.maps) {
      setApiLoaded(true)
    } else {
      // If not, wait for the 'google-maps-api-loaded' event
      window.addEventListener("google-maps-api-loaded", () => {
        setApiLoaded(true)
      })
    }
  }, [])

  if (!apiLoaded) {
    return <div>Loading...</div>
  }

  //Doc Send City
  const setDocSendCity = address => {
    setFormData({ ...FormData, send_location: address })
  }
  const setDocrecCity = address => {
    setFormData({ ...FormData, receive_location: address })
  }

  //Service Charges Data Options
  const subServicesOptions = [
    { label: "Over Night", value: "overnight" },
    { label: "Flyer", value: "flyer" },
    { label: "Box", value: "box" },
  ]

  const sectorOption = [
    { label: "Within City", value: "wc" },
    { label: "Same Zone", value: "sz" },
    { label: "Diff Zone", value: "dz" },
  ]

  //weight Options
  const weightOptions = [
    { label: "0.5", value: "0.5" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
  ]

  const handleWeight = selectedOption => {
    setFormData(prevData => ({
      ...prevData,
      content_weight: selectedOption.value,
    }))
    setParcelWeight(selectedOption)
  }

  //Handle SubService
  const handleSubService = selectedOption => {
    if (selectedOption) {
      setFormData(prevData => ({
        ...prevData,
        sub_service_name: selectedOption.value,
      }))
      setSubService(selectedOption)

      // Check the selected sub-service
      if (selectedOption.value === "box") {
        // If selected sub-service is 'box'
        setParcelWeight({
          value: "1",
          label: "1",
        })
      } else {
        // If selected sub-service is not 'box'
        setParcelWeight({
          value: "0.5",
          label: "0.5",
        })
      }
    }
  }

  //Handle Sector
  const handleSector = selectedOption => {
    setFormData(prevData => ({
      ...prevData,
      sector_: selectedOption.value,
    }))
    setSector(selectedOption)
  }

  //Close
  const close = () => {
    navigate("/")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Book Courier" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle>Service Info</CardTitle>
                  <Form className="mt-2">
                    <Row>
                      <Col lg="2">
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">
                            Employee Name
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder=""
                            name="employee_name"
                            value={FormData.employee_name}
                            onChange={handleParcel}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg="2">
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">
                            Employee ID
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder=""
                            name="employee_id"
                            value={FormData.employee_id}
                            onChange={handleParcel}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={DocumentMode ? "4" : "4"}>
                        <div className="mb-3">
                          <Label>Services</Label>
                          <Select
                            value={MainService}
                            onChange={handleMainService}
                            name="service_name"
                            options={MSoption}
                            className="select2-selection"
                          />
                        </div>
                      </Col>

                      {!DocumentMode && (
                        <>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label>Sub Services</Label>
                              <Select
                                value={SubService}
                                onChange={handleSubService}
                                name="sub_service_name"
                                options={subServicesOptions}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg="2">
                            <div className="mb-3">
                              <Label>Set Sector</Label>
                              <Select
                                value={Sector}
                                onChange={handleSector}
                                name="sector_"
                                options={sectorOption}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                        </>
                      )}

                      {DocumentMode && (
                        <Col lg="4">
                          <div className="mb-3">
                            <Label>Sub Services</Label>
                            <Select
                              value={DocSubService}
                              onChange={handleDocSubService}
                              name="doc_sub_service"
                              options={DocSSOption}
                              className="select2-selection"
                            />
                          </div>
                        </Col>
                      )}
                    </Row>
                    {!DocumentMode && (
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label htmlFor="address_">Send Location</Label>
                            <PlacesAutocomplete
                              value={FormData.send_location}
                              onChange={handleChange}
                              onSelect={handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Set send location...",
                                      className: "form-control",
                                      id: "address_",
                                    })}
                                  />
                                  <div>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item"
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          }
                                      return (
                                        <div
                                          key={index}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <i className="bx bx-map"></i>
                                          <span>{suggestion.description}</span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label htmlFor="address2_">Receive Location</Label>
                            <PlacesAutocomplete
                              value={FormData.receive_location}
                              onChange={handleChange2}
                              onSelect={handleSelect2}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Set receive location...",
                                      className: "form-control",
                                      id: "address2_",
                                    })}
                                  />
                                  <div>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item"
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          }
                                      return (
                                        <div
                                          key={index}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <i className="bx bx-map"></i>
                                          <span>{suggestion.description}</span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {DocumentMode && (
            <Row xl="12">
              <Card>
                <CardBody>
                  <CardTitle>Documnets Detail</CardTitle>
                  <Row>
                    <Col lg={3}>
                      <div className="mb-3">
                        <label>Delivery Options</label>
                        <select
                          className="form-select"
                          name="office_door_option"
                          value={FormData.office_door_option}
                          onChange={handleOfficDoorService}
                        >
                          <option value="">Select...</option>
                          <option value="oto_">Office to Office</option>
                          <option value="otd_">Office to Door Step</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <label>Delivery Options</label>
                        <select
                          className="form-select"
                          name="city_options"
                          value={FormData.city_options}
                          onChange={handlecityoptions}
                        >
                          <option value="">Select...</option>
                          <option value="wc_">With-in City</option>
                          <option value="ctc_">City To City</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label htmlFor="no_of_docs">No Of Docs.</Label>
                        <Input
                          min={1}
                          type="number"
                          className="form-control"
                          id="no_of_docs"
                          name="no_of_docs"
                          value={FormData.no_of_docs}
                          onChange={handledocsNo}
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label htmlFor="no_of_docs">CVI Amount</Label>
                        <Input
                          min={1}
                          type="number"
                          className="form-control"
                          name="doc_cvi_amount"
                          value={FormData.doc_cvi_amount}
                          onChange={handleParcel}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {wc && (
                      <Col xl="3">
                        <div className="mb-3">
                          <Label htmlFor="address_">Set City</Label>
                          <PlacesAutocomplete
                            value={FormData.send_location}
                            onChange={setDocSendCity}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Send location...",
                                    className: "form-control",
                                    id: "address_",
                                  })}
                                />
                                <div>
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item"
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        }
                                    return (
                                      <div
                                        key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <i className="bx bx-map"></i>
                                        <span>{suggestion.description}</span>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </Col>
                    )}
                    {ctc && (
                      <>
                        <Col xl="3">
                          <div className="mb-3">
                            <Label htmlFor="address_">From City</Label>
                            <PlacesAutocomplete
                              value={FormData.send_location}
                              onChange={setDocSendCity}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Send location...",
                                      className: "form-control",
                                      id: "address_",
                                    })}
                                  />
                                  <div>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item"
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          }
                                      return (
                                        <div
                                          key={index}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <i className="bx bx-map"></i>
                                          <span>{suggestion.description}</span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </Col>
                        <Col xl="3">
                          <div className="mb-3">
                            <Label htmlFor="address_">To City</Label>
                            <PlacesAutocomplete
                              value={FormData.receive_location}
                              onChange={setDocrecCity}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Receive location...",
                                      className: "form-control",
                                      id: "address_",
                                    })}
                                  />
                                  <div>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item"
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          }
                                      return (
                                        <div
                                          key={index}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <i className="bx bx-map"></i>
                                          <span>{suggestion.description}</span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </Col>
                      </>
                    )}
                    <Col xl="6">
                      <div className="mb-3">
                        <Label htmlFor="document_detail">Document Detail</Label>
                        <Input
                          type="textarea"
                          id="document_detail"
                          rows="3"
                          placeholder="Detail"
                          name="document_detail"
                          value={FormData.document_detail}
                          onChange={handleParcel}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>
          )}

          <Row>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Sender Information</h4>
                  <Form>
                    <Row>
                      <Col lg="8">
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Full Name *
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="First Name"
                            name="s_fullName"
                            value={FormData.s_fullName}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Phone *</Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Ph no"
                            name="s_phone"
                            value={FormData.s_phone}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label htmlFor="cnic_">CNIC</Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="cnic_"
                            placeholder="CNIC"
                            name="s_cnic"
                            value={FormData.s_cnic}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                      <Col xl="12">
                        <div className="mb-3">
                          <Label htmlFor="s_address">Address*</Label>
                          <Input
                            type="textarea"
                            id="s_address"
                            rows="3"
                            placeholder="address"
                            name="s_address"
                            value={FormData.s_address}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Receiver Information</h4>
                  <Form>
                    <Row>
                      <Col lg="8">
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Full Name *
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="First Name"
                            name="r_fullName"
                            value={FormData.r_fullName}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Phone *</Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Ph no"
                            name="r_phone"
                            value={FormData.r_phone}
                            onChange={handleParcel}
                            disabled={DocumentMode}
                          />
                        </div>
                      </Col>
                      {!DocumentMode && (
                        <Col lg="6">
                          <div className="mb-3">
                            <Label htmlFor="cnic_">CNIC</Label>
                            <Input
                              type="number"
                              className="form-control"
                              id="cnic_"
                              placeholder="CNIC"
                              name="r_cnic"
                              value={FormData.r_cnic}
                              onChange={handleParcel}
                              // disabled={DocumentMode}
                            />
                          </div>
                        </Col>
                      )}

                      <Col xl="12">
                        <div className="mb-3">
                          <Label htmlFor="r_address">Address*</Label>
                          <Input
                            type="textarea"
                            id="r_address"
                            rows="3"
                            placeholder="address"
                            name="r_address"
                            value={FormData.r_address}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {DocumentMode ? (
              <>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>Details</CardTitle>
                      <Form>
                        <Row>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label>Assign To</Label>
                              <Select
                                value={Rname}
                                onChange={handleRider}
                                name="assign_to"
                                options={RiderOption}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <label>Payment Method</label>
                              <select
                                className="form-select"
                                name="payment_method"
                                value={FormData.payment_method}
                                onChange={handleParcel}
                              >
                                <option>Select..</option>
                                {paymentM && paymentM.length > 0 ? (
                                  paymentM
                                    .filter(e => !!e)
                                    .map(
                                      (
                                        e,
                                        index // Reorder the parameters of the map function
                                      ) => (
                                        <option
                                          key={index}
                                          value={e.method_name}
                                        >
                                          {e.method_name}
                                        </option>
                                      )
                                    )
                                ) : (
                                  <option>No Data Founr</option>
                                )}
                              </select>
                            </div>
                          </Col>

                          <Col lg={3}>
                            <div className="form-check mt-4">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="invalidCheck"
                                onClick={handleDocCheckbox}
                                checked={isChecked}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="invalidCheck"
                              >
                                {" "}
                                CVI (2.5 %)
                              </Label>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3 float-end">
                              <Label htmlFor="Docs_subtotal_">
                                Total Price (pkr)
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="Docs_subtotal_"
                                name="Docs_subtotal_"
                                value={Number(FormData.Docs_subtotal_).toFixed(
                                  2
                                )}
                                onChange={handleParcel}
                                disabled
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : (
              <>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Parcel Information</h4>
                      <Form>
                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="parcel_type">
                                No. Of Contents
                              </Label>
                              <Input
                                type="number"
                                min={1}
                                className="form-control"
                                id="parcel_type"
                                name="content_no"
                                value={FormData.content_no}
                                onChange={handleParcel}
                              />
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label>Weight (kg)</Label>
                              <Select
                                value={parcelWeight}
                                onChange={handleWeight}
                                placeholder="Weight"
                                name="content_weight"
                                options={weightOptions}
                                className="select2-selection"
                              />
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="parcel_price">
                                Content Value
                              </Label>
                              <Input
                                type="number"
                                min={0}
                                className="form-control"
                                id="parcel_price"
                                placeholder="0.00"
                                name="contents_value"
                                value={FormData.contents_value}
                                onChange={handleParcel}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="mb-3">
                          <Label htmlFor="content_info">Content Name</Label>
                          <Input
                            type="textarea"
                            id="content_info"
                            rows="3"
                            placeholder="Content"
                            name="content_info"
                            value={FormData.content_info}
                            onChange={handleParcel}
                          />
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label>Assign To</Label>
                              <Select
                                value={Rname}
                                onChange={handleRider}
                                name="assign_to"
                                options={RiderOption}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <label>Payment Method</label>
                              <select
                                className="form-select"
                                name="payment_method"
                                value={FormData.payment_method}
                                onChange={handleParcel}
                              >
                                <option>Select..</option>
                                {paymentM && paymentM.length > 0 ? (
                                  paymentM
                                    .filter(e => !!e)
                                    .map(
                                      (
                                        e,
                                        index // Reorder the parameters of the map function
                                      ) => (
                                        <option
                                          key={index}
                                          value={e.method_name}
                                        >
                                          {e.method_name}
                                        </option>
                                      )
                                    )
                                ) : (
                                  <option>No Data Founr</option>
                                )}
                              </select>
                            </div>
                          </Col>

                          <Col lg={3}>
                            <div className="form-check mt-4">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="invalidCheck2"
                                onClick={handleDocCheckbox}
                                checked={isChecked} // Conditionally set checked attribute
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="invalidCheck2"
                              >
                                {" "}
                                CVI (2.5 %)
                              </Label>
                            </div>
                          </Col>

                          <Col lg="3">
                            <div className="mb-3 float-end">
                              <Label htmlFor="subtotal_">
                                Total Price (pkr)
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="subtotal_"
                                name="subtotal_"
                                value={Number(FormData.subtotal_).toFixed(2)}
                                onChange={handleParcel}
                                disabled
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </>
            )}

            <Row className="mb-3">
              <Col xl={4}>
                <Button
                  color="danger"
                  className="btn btn-danger waves-effect waves-light"
                  onClick={close}
                >
                  Close
                </Button>
              </Col>
              <Col xl={8}>
                <div className="float-end">
                  <Button
                    color="success"
                    className={`btn btn-secondary waves-effect waves-light ${
                      loading ? "disabled" : ""
                    }`}
                    onClick={handleSubmit}
                  >
                    Save & Close
                  </Button>
                </div>
              </Col>
            </Row>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default CourierInfo
