import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import images
import profile from "assets/images/ecs.png"
import logo from "assets/images/logo.svg"

import axios from "axios"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import apiURl from "url"

const Login = props => {
  //meta title
  document.title = "Login | ECS"

  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const [formData, setFormData] = useState({
    email_: "",
    password_: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const loginTime = new Date().getTime() // Current timestamp
  const expirationTime = loginTime + 5 * 60 * 60 * 1000

  const login = e => {
    setLoading(true)
    e.preventDefault()
    if (formData.email_.length === 0) {
      toastr.error("Email is required")
    } else if (formData.password_.length === 0) {
      toastr.error("Password is required")
    } else {
      const URL = `${apiURl}/loginFunction`
      const data = {
        email: formData.email_,
        password: formData.password_,
      }
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.status === "success") {
            setLoading(false)
            // Handle successful login
            const { id, user_id, full_name, email_, role_, password_, city_ } =
              response.data.user
            localStorage.setItem("authUser", id)
            localStorage.setItem("login_id", id)
            localStorage.setItem("login_user_id", user_id)
            localStorage.setItem("full_name", full_name)
            localStorage.setItem("email", email_)
            localStorage.setItem("role", role_)
            localStorage.setItem("password", password_)
            localStorage.setItem("city", city_)
            localStorage.setItem("loginTime", loginTime)
            localStorage.setItem("expirationTime", expirationTime)

            navigate("/dashboard")
            toastr.success("Login Successfully")
          } else if (response.status === 401) {
            // alert("Invalid email or password")
            setLoading(false)
            toastr.error("Invalid email or password")
          } else if (response.data.status === "error") {
            setLoading(false)
            // alert("Invalid email or password")
            toastr.error("Invalid email or password")
          }
        })
        .catch(error => {
          setLoading(false)
          toastr.error("Invalid email or password")

          console.error("Invalid email or password: ", error)
        })
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row
            className="justify-content-center"
            style={{ backgroundColor: "#F8F8FB" }}
          >
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row className="d-flex justify-content-center">
                    <Col xl={12} className="text-center">
                      <img
                        src={profile}
                        alt=""
                        width={100}
                        height={100}
                        className="img-fluid"
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form className="form-horizontal">
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email_"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={handleChange}
                          value={formData.email_}
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <InputGroup>
                          <Input
                            name="password_"
                            value={formData.password_}
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={handleChange}
                          />
                          <InputGroupText
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                            />
                          </InputGroupText>
                        </InputGroup>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      {loading ? (
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled
                          >
                            <Spinner />
                          </button>
                        </div>
                      ) : (
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            onClick={login}
                          >
                            Log In
                          </button>
                        </div>
                      )}
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
